.fade-left {
   animation: fade-left 750ms forwards;
}

@keyframes fade-left {
   from {
      opacity: 0;
      transform: translateX(-75px);
   }

   to {
      opacity: 1;
      transform: translateY(0);
   }
}

.fade-right {
   animation: fade-right 750ms forwards;
}

@keyframes fade-right {
   from {
      opacity: 0;
      transform: translateX(75px);
   }

   to {
      opacity: 1;
      transform: translateY(0);
   }
}
